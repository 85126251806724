.App {
  text-align: center;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-header.App-header-results {
  min-height: auto;
}

.App-header-label {
  display: inline-block;
  font-size: calc((75vw - 4.5rem) / 14);
  margin-bottom: 24px;
}

.App-header-input {
  width: 80%;
  font-size: calc((75vw - 4.5rem) / 16);
  text-align: center;
  border: 1px solid #000000;
  border-right: none;
}

.App-header-input:focus {
  outline: none;
}

.App-header-input-button {
  font-size: calc((75vw - 4.5rem) / 30);
  padding-left: 24px;
  padding-right: 24px;
  outline: none;
  border: 1px solid black;
}

.App-header-input-button:hover {
  background: #000;
  color: #fff;
  cursor: pointer;
}

.App-header-search {
  margin-top: calc((150vh - 4.5rem) / 5);
}

.App-header-input-container {
  display: flex;
  justify-content: center;
}

.App-results {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: auto auto;
  width: 80%;
  margin: 0 auto 300px auto;
}

.App-header-top {
  position: fixed;
  top: 0;
  width: 100%;
  background: #000;
  height: 80px;
  color: #ffffff;
}

.App-header-results-title {
  font-size: 60px;
  margin-top: 150px;
  margin-bottom: 80px;
  padding-left: 40px;
  padding-right: 40px;
}

.App-results-item {
  position: relative;
  text-align: center;
  padding: 24px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.App-results-item h1 {
  margin-top: 0;
}

/*.App-results-item:hover {*/
/*  cursor: pointer;*/
/*  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);*/
/*}*/

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin-bottom: 12px;
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #000000;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #000000;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

.App-header-link {
  display: block;
  width: 100%;
}

@media (max-width: 600px) {
  .App-header-search {
    margin-top: 150px;
    max-width: 80%;
  }
  .App-header-label {
    font-size: calc((75vw - 4.5rem) / 8);
    margin-bottom: 12px;
  }
  .App-header-input {
    font-size: calc((75vw - 4.5rem) / 10);
  }
  .App-header-input-button {
    font-size: calc((75vw - 4.5rem) / 20);
  }
  .App-header-results-title {
    font-size: calc((75vw - 4.5rem) / 8);
  }
  .App-results-item.first {
    padding-top: 100px;
  }
}

@media (max-width: 1200px) {
  .App-results {
    grid-template-columns: auto;
  }
}